html,
body,
#root {
    min-height: 100vh;
}

/* For dark mode */
@media (prefers-color-scheme: dark) {
    html,
    body {
        background-color: rgb(15 23 42); /* slate-900 */
        background-image: linear-gradient(315deg, rgb(15 23 42), #12100e);
    }
}

/* If you're using class-based dark mode */
.dark html,
.dark body {
    background-color: rgb(15 23 42); /* slate-900 */
    background-image: linear-gradient(315deg, rgb(15 23 42), #12100e);
}
